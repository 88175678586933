import React from 'react';
import { Box } from '@sanch941/lib';
import { CommonTemplate } from '@ui';
import { MenuTemplate } from './templates/menu/menu';
import { QuestionsTemplate } from './templates/questions/questions';

export const FaqPage = () => (
    <CommonTemplate>
        <Box $top={28} $bottom={20}>
            <MenuTemplate />
            <QuestionsTemplate />
        </Box>
    </CommonTemplate>
);
