import { graphql } from 'gatsby';
import { FaqPage } from '../page-templates/faq/faq';

export default FaqPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
