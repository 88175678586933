import React, { useMemo } from 'react';
import {
    Box,
    StrapiListItemFromRes,
    mergeArrByKey,
    pxRem
} from '@sanch941/lib';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Container } from '@ui';
import { Card } from './card';
import {
    CartIcon,
    IntegrationIcon,
    PortfolioIcon,
    ProfileIcon,
    WalletIcon
} from './icons';
import { useAppDispatch, useAppSelector } from '@store';
import styled from 'styled-components';

export const MenuTemplate = () => {
    const { t } = useTranslation();
    const selectedMenuId = useAppSelector(({ faq }) => faq.selectedMenuId);
    const categories: StrapiListItemFromRes[] = t('faq.categories');

    const dispatch = useAppDispatch();

    const categoriesWithIcons: CategoryWithIcon[] = useMemo(() => {
        return mergeArrByKey(categories, icons, 'my_id', 'categoryId');
    }, []);

    return (
        <Container>
            <StyledBox
                $top={20}
                $bottom={50}
                $flex
                fxw="wrap"
                fxd="column"
                jc="space-between"
                sm={{ $top: 40, $bottom: 53, fxd: 'row' }}
            >
                {categoriesWithIcons.map((category) => (
                    <Card
                        active={selectedMenuId === category.my_id}
                        dispatch={dispatch}
                        key={category.my_id}
                        {...category}
                    />
                ))}
            </StyledBox>
        </Container>
    );
};

const StyledBox = styled(Box)`
    gap: ${pxRem(30)};
`;
export interface CategoryWithIcon extends StrapiListItemFromRes {
    Icon: () => JSX.Element;
    categoryId: number;
}

const icons = [
    {
        categoryId: 1,
        Icon: ProfileIcon
    },
    {
        categoryId: 2,
        Icon: PortfolioIcon
    }
    // {
    //     categoryId: 3,
    //     Icon: ProfileIcon
    // },
    // {
    //     categoryId: 4,
    //     Icon: WalletIcon
    // },
    // {
    //     categoryId: 5,
    //     Icon: PortfolioIcon
    // }
    // {
    //     categoryId: 6,
    //     Icon: OthersIcon
    // }
];
