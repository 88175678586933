import { colors, pxRem, RichText } from '@lib';
import { faqActions } from '@page-templates/faq/model';
import { Box, media } from '@sanch941/lib';
import { AppDispatch } from '@store';
import React, { FC } from 'react';
import styled from 'styled-components';
import { CategoryWithIcon } from './menu';

export const Card: FC<ComponentProps> = ({
    rich_name,
    active,
    dispatch,
    my_id,
    Icon
}) => {
    const onClick = () => !active && dispatch(faqActions.toggleMenuItem(my_id));

    return (
        <StyledContainer onClick={onClick}>
            <Box>
                <StyledIcon active={active}>
                    <Icon />
                </StyledIcon>
            </Box>

            <RichText
                ta="center"
                $color={active ? colors.mainPurple : colors.mainBlack}
                fz={10}
                lh={12}
                sm={{
                    fz: 16,
                    lh: 19
                }}
                text={rich_name}
            />
        </StyledContainer>
    );
};

interface ComponentProps extends CategoryWithIcon {
    active: boolean;

    dispatch: AppDispatch;
}

const StyledContainer = styled.div`
    height: ${pxRem(50)};
    background: #ffffff;
    width: 100%;
    gap: ${pxRem(10)};
    box-shadow: 0px 5px 20px rgba(51, 23, 94, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: ${pxRem(12)} ${pxRem(20)};
    cursor: pointer;

    ${media.md} {
        margin-top: 0;
        gap: ${pxRem(20)};
        width: 48%;
    }
`;

const StyledIcon = styled.div<{ active: boolean }>`
    svg {
        width: ${pxRem(30)};
        height: ${pxRem(30)};

        path {
            fill: ${({ active }) => (active ? colors.mainPurple : '#7C7C7C')};
        }
    }
`;
