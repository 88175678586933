import React, { useEffect, useMemo } from 'react';
import { pxRem, StrapiListItemFromRes, Text } from '@sanch941/lib';
import { Container } from '@ui';
import { Card } from './card';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { faqActions } from '@page-templates/faq/model';
import { useAppDispatch, useAppSelector } from '@store';
import styled from 'styled-components';
import { AppStateType } from '@store/root-reducer';
interface Question extends StrapiListItemFromRes {
    open?: boolean;
}

export const QuestionsTemplate = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(faqActions.setQuestions(t('faq.questions')));
    }, []);

    const { selectedMenuId, questions } = useAppSelector(
        ({ faq: { selectedMenuId, questions } }: AppStateType) => ({
            selectedMenuId,
            questions
        })
    );

    const mappedQuestions = useMemo(() => {
        return selectedMenuId === 0
            ? questions
            : questions.filter(
                  ({ related_id }) => related_id === selectedMenuId
              );
    }, [questions, selectedMenuId]);

    return (
        <>
            <Container>
                <Text $color="#470BBE" fw={700} fz={18} sm={{ fz: 24 }}>
                    {t('frequently_questions')}:
                </Text>
            </Container>

            <Container mobilePadding={false}>
                <StyledQuestions>
                    {mappedQuestions.map((question, idx) => (
                        <Card {...question} key={idx} />
                    ))}
                </StyledQuestions>
            </Container>
        </>
    );
};

const StyledQuestions = styled.div`
    padding-top: ${pxRem(10)};
`;
