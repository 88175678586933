import {
    Box,
    Collapsible,
    media,
    pxRem,
    StrapiListItemFromRes
} from '@sanch941/lib';
import React, { FC } from 'react';
import styled from 'styled-components';
import caret from '@assets/images/faq/caret.svg';
import { colors, RichText } from '@lib';
import { useDispatch } from 'react-redux';
import { faqActions } from '../../model';

const { toggleFaqItem } = faqActions;

export const Card: FC<ComponentProps> = ({
    rich_name,
    rich_description,
    open,
    id
}) => {
    const dispatch = useDispatch();

    return (
        <StyledContainer open={open}>
            <Collapsible
                open={open}
                $onClick={() =>
                    dispatch(
                        toggleFaqItem({
                            id,
                            open: !open
                        })
                    )
                }
                VisiblePart={<VisiblePart title={rich_name} open={open} />}
                Inner={<Inner description={rich_description} />}
            />
        </StyledContainer>
    );
};

const VisiblePart = ({ title, open }) => (
    <StyledVisiblePartContainer $flex ai="center" jc="space-between">
        <RichText
            $color={open ? colors.mainPurple : colors.mainBlack}
            fz={14}
            sm={{ fz: 18 }}
            text={title}
        />

        <StyledIcon open={open} src={caret} />
    </StyledVisiblePartContainer>
);

const Inner = ({ description }) => (
    <StyledInner $width="100%">
        <RichText fz={14} lh={16} sm={{ fz: 18, lh: 21 }} text={description} />
    </StyledInner>
);

interface ComponentProps extends StrapiListItemFromRes {
    open?: boolean;
}

const StyledContainer = styled.div<{ open: boolean }>`
    transition: background-color 0.2s;
    background-color: ${({ open }) => (open ? '#FAFAFA' : 'white')};
    border-bottom: 1px solid #e0e0e0;
`;

const StyledVisiblePartContainer = styled(Box)`
    padding: ${pxRem(15)} ${pxRem(13)};

    ${media.md} {
        padding: ${pxRem(30)} ${pxRem(20)};
    }
`;

const StyledIcon = styled.img<{ open: boolean }>`
    transition: transform 0.2s ease-out;
    width: ${pxRem(15)};
    transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0)')};

    ${media.md} {
        width: ${pxRem(19)};
    }
`;

const StyledInner = styled(Box)`
    padding: 0 ${pxRem(25)} ${pxRem(31)};

    ${media.md} {
        padding: 0 ${pxRem(40)} ${pxRem(40)};
    }
`;
