import React from 'react';

export const CartIcon = () => (
    <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.6516 14.0414H21.1114C21.636 14.0414 22.0483 13.6076 22.0483 13.0846C22.0483 12.5489 21.636 12.1279 21.1114 12.1279H17.6516C17.127 12.1279 16.7149 12.5489 16.7149 13.0846C16.7149 13.6076 17.127 14.0414 17.6516 14.0414ZM25.2208 7.40936C25.9826 7.40936 26.4823 7.67725 26.9819 8.26404C27.4815 8.85083 27.5689 9.69275 27.4565 10.4569L26.2699 18.825C26.0451 20.4336 24.6961 21.6186 23.1099 21.6186H9.48299C7.82178 21.6186 6.44785 20.3187 6.31047 18.635L5.16135 4.72925L3.27533 4.39759C2.77572 4.3083 2.42599 3.8108 2.51342 3.30054C2.60085 2.77881 3.08798 2.43311 3.60008 2.51092L6.579 2.96887C7.00367 3.04668 7.31593 3.40259 7.3534 3.83631L7.59072 6.69374C7.62818 7.10321 7.95293 7.40936 8.35262 7.40936H25.2208ZM9.28289 23.6349C8.23372 23.6349 7.38438 24.5022 7.38438 25.5737C7.38438 26.6326 8.23372 27.5 9.28289 27.5C10.3196 27.5 11.1689 26.6326 11.1689 25.5737C11.1689 24.5022 10.3196 23.6349 9.28289 23.6349ZM23.3345 23.6349C22.2853 23.6349 21.4359 24.5022 21.4359 25.5737C21.4359 26.6326 22.2853 27.5 23.3345 27.5C24.3711 27.5 25.2205 26.6326 25.2205 25.5737C25.2205 24.5022 24.3711 23.6349 23.3345 23.6349Z"
            fill="#7C7C7C"
        />
    </svg>
);

export const IntegrationIcon = () => (
    <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.5029 16.9751C25.95 17.2126 26.295 17.5876 26.5376 17.9626C27.0103 18.7376 26.972 19.6876 26.5121 20.5251L25.6179 22.0251C25.1453 22.8251 24.2638 23.3251 23.3569 23.3251C22.9098 23.3251 22.4115 23.2001 22.0028 22.9501C21.6706 22.7376 21.2874 22.6626 20.8786 22.6626C19.6139 22.6626 18.5536 23.7001 18.5153 24.9376C18.5153 26.3751 17.34 27.5001 15.871 27.5001H14.1336C12.6518 27.5001 11.4766 26.3751 11.4766 24.9376C11.451 23.7001 10.3907 22.6626 9.12607 22.6626C8.70451 22.6626 8.32127 22.7376 8.00191 22.9501C7.59313 23.2001 7.08215 23.3251 6.64782 23.3251C5.72806 23.3251 4.84662 22.8251 4.37396 22.0251L3.49252 20.5251C3.01987 19.7126 2.99432 18.7376 3.46697 17.9626C3.67137 17.5876 4.0546 17.2126 4.48893 16.9751C4.84662 16.8001 5.07656 16.5126 5.29372 16.1751C5.93245 15.1001 5.54921 13.6876 4.46338 13.0501C3.19871 12.3376 2.78993 10.7501 3.51807 9.51262L4.37396 8.03762C5.11488 6.80012 6.69892 6.36262 7.97636 7.08762C9.08775 7.68762 10.5313 7.28762 11.1828 6.22512C11.3871 5.87512 11.5021 5.50012 11.4766 5.12512C11.451 4.63762 11.5915 4.17512 11.8342 3.80012C12.3069 3.02512 13.1628 2.52512 14.0954 2.50012H15.8965C16.8419 2.50012 17.6978 3.02512 18.1704 3.80012C18.4004 4.17512 18.5536 4.63762 18.5153 5.12512C18.4898 5.50012 18.6048 5.87512 18.8091 6.22512C19.4606 7.28762 20.9041 7.68762 22.0283 7.08762C23.293 6.36262 24.8898 6.80012 25.6179 8.03762L26.4738 9.51262C27.2148 10.7501 26.8059 12.3376 25.5285 13.0501C24.4426 13.6876 24.0594 15.1001 24.7109 16.1751C24.9153 16.5126 25.1453 16.8001 25.5029 16.9751ZM11.3871 15.0126C11.3871 16.9751 13.0095 18.5376 15.0151 18.5376C17.0206 18.5376 18.6048 16.9751 18.6048 15.0126C18.6048 13.0501 17.0206 11.4751 15.0151 11.4751C13.0095 11.4751 11.3871 13.0501 11.3871 15.0126Z"
            fill="#7C7C7C"
        />
    </svg>
);

export const ProfileIcon = () => (
    <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.6175 9.11381C21.6175 12.7851 18.6739 15.7289 15 15.7289C11.3274 15.7289 8.38251 12.7851 8.38251 9.11381C8.38251 5.44252 11.3274 2.5 15 2.5C18.6739 2.5 21.6175 5.44252 21.6175 9.11381ZM15 27.5C9.57796 27.5 5 26.6188 5 23.2188C5 19.8174 9.60672 18.9674 15 18.9674C20.4233 18.9674 25 19.8486 25 23.2488C25 26.65 20.3932 27.5 15 27.5Z"
            fill="#7C7C7C"
        />
    </svg>
);

export const WalletIcon = () => (
    <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.2111 10.4772H27.5C27.5 6.23074 24.9555 3.75 20.6445 3.75H9.35555C5.04445 3.75 2.5 6.23074 2.5 10.4231V19.5769C2.5 23.7692 5.04445 26.25 9.35555 26.25H20.6445C24.9555 26.25 27.5 23.7692 27.5 19.5769V19.1869H22.2111C19.7565 19.1869 17.7666 17.2469 17.7666 14.8538C17.7666 12.4606 19.7565 10.5206 22.2111 10.5206V10.4772ZM22.2111 12.3405H26.5666C27.0821 12.3405 27.5 12.7479 27.5 13.2505V16.4137C27.494 16.9139 27.0796 17.3179 26.5666 17.3236H22.3111C21.0685 17.34 19.9819 16.5105 19.7 15.3304C19.5589 14.5979 19.757 13.842 20.2414 13.2652C20.7256 12.6886 21.4466 12.3501 22.2111 12.3405ZM22.4 15.6663H22.8111C23.3389 15.6663 23.7666 15.2491 23.7666 14.7346C23.7666 14.2201 23.3389 13.803 22.8111 13.803H22.4C22.1476 13.8001 21.9045 13.8958 21.725 14.0688C21.5455 14.2417 21.4444 14.4776 21.4445 14.7238C21.4444 15.2401 21.8705 15.6602 22.4 15.6663ZM8.42222 10.4772H15.4778C16.0055 10.4772 16.4334 10.0601 16.4334 9.54561C16.4334 9.03109 16.0055 8.61399 15.4778 8.61399H8.42222C7.89879 8.61395 7.47275 9.0245 7.46666 9.53479C7.46664 10.0511 7.89269 10.4713 8.42222 10.4772Z"
            fill="#7C7C7C"
        />
    </svg>
);

export const PortfolioIcon = () => (
    <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.3805 4.39873C12.5425 4.39873 11.8297 4.981 11.6296 5.76581H18.3579C18.1579 4.981 17.445 4.39873 16.607 4.39873H13.3805ZM20.2589 5.7658H22.7351C25.3614 5.7658 27.5 7.93035 27.5 10.5886C27.5 10.5886 27.425 11.7139 27.4 13.281C27.3975 13.405 27.3374 13.5265 27.2386 13.6C26.6371 14.0443 26.0867 14.4114 26.0367 14.4366C23.9607 15.829 21.5483 16.8088 18.9783 17.2961C18.8106 17.329 18.6456 17.2417 18.5605 17.0924C17.8401 15.8442 16.4945 15.0316 14.9937 15.0316C13.503 15.0316 12.1448 15.8354 11.4032 17.0848C11.3169 17.2316 11.1543 17.3164 10.988 17.2847C8.43922 16.7961 6.02676 15.8176 3.96324 14.4492L2.76264 13.6139C2.66259 13.5506 2.60005 13.4366 2.60005 13.3101C2.56254 12.6645 2.5 10.5886 2.5 10.5886C2.5 7.93035 4.63858 5.7658 7.26489 5.7658H9.72861C9.96624 3.93037 11.5045 2.5 13.3805 2.5H16.607C18.483 2.5 20.0212 3.93037 20.2589 5.7658ZM27.0747 16.019L27.0247 16.0444C24.4985 17.7405 21.4595 18.8671 18.2704 19.3355C17.8201 19.3988 17.3699 19.1076 17.2449 18.652C16.9698 17.614 16.0818 16.9304 15.0188 16.9304H15.0063H14.9812C13.9182 16.9304 13.0302 17.614 12.7551 18.652C12.6301 19.1076 12.1798 19.3988 11.7296 19.3355C8.54052 18.8671 5.5015 17.7405 2.97524 16.0444C2.96274 16.0317 2.83767 15.9557 2.73762 16.019C2.62506 16.0824 2.62506 16.2343 2.62506 16.2343L2.71261 22.6899C2.71261 25.3481 4.83868 27.5 7.46499 27.5H22.5225C25.1489 27.5 27.2749 25.3481 27.2749 22.6899L27.375 16.2343C27.375 16.2343 27.375 16.0824 27.2624 16.019C27.1999 15.9811 27.1249 15.9937 27.0747 16.019ZM15.9318 21.3229C15.9318 21.8545 15.519 22.2721 14.9937 22.2721C14.481 22.2721 14.0557 21.8545 14.0557 21.3229V19.6899C14.0557 19.1709 14.481 18.7405 14.9937 18.7405C15.519 18.7405 15.9318 19.1709 15.9318 19.6899V21.3229Z"
            fill="#7C7C7C"
        />
    </svg>
);

export const OthersIcon = () => (
    <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.5 14.9999C2.5 8.09988 8.0875 2.49988 15 2.49988C21.9 2.49988 27.5 8.09988 27.5 14.9999C27.5 21.8999 21.9 27.4999 15 27.4999C8.0875 27.4999 2.5 21.8999 2.5 14.9999ZM9.4 16.4999C8.575 16.4999 7.9 15.8249 7.9 14.9999C7.9 14.1749 8.575 13.5011 9.4 13.5011C10.225 13.5011 10.8875 14.1749 10.8875 14.9999C10.8875 15.8249 10.225 16.4999 9.4 16.4999ZM13.5 14.9999C13.5 15.8249 14.175 16.4999 15 16.4999C15.825 16.4999 16.4875 15.8249 16.4875 14.9999C16.4875 14.1749 15.825 13.5011 15 13.5011C14.175 13.5011 13.5 14.1749 13.5 14.9999ZM19.1 14.9999C19.1 15.8249 19.7625 16.4999 20.6 16.4999C21.425 16.4999 22.0875 15.8249 22.0875 14.9999C22.0875 14.1749 21.425 13.5011 20.6 13.5011C19.7625 13.5011 19.1 14.1749 19.1 14.9999Z"
            fill="#7C7C7C"
        />
    </svg>
);
